
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, map, take, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { mUnit } from "../classes/mUnit";
import { GlobalErrorHandler } from './error-handler.service';


@Injectable()
export class InventoryService {
    
    constructor(private http: HttpClient,private errorService:GlobalErrorHandler) {
    }



    InventorySettingList(): Observable<any> {
        return this.http.post("/api/getAllInventorySettings/", {}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),map((response) => response));
    }

    getACL(munit): Observable<any> {
        return this.http.get("/api/projectBymUnit/"+munit, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }

    updateInventorySetting(munit): Observable<any> {
        return this.http.post("/api/updateInventorySettings/", munit, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }

    getAllFileDetails(): Observable<any> {
        return this.http.post("/api/getAllInventoryItems/",{}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    
    getFileDetailsByMunit(munit): Observable<any> {
        return this.http.post("/api/getInventoryItemsBymunit/", {munit}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    getFileDetailsByMunitArray(munitArr): Observable<any> {
        return this.http.post("/api/getInventoryItemsBymunitArray/", {munitArr}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),
    map((response) => response));
    }
    DeleteFile(_id): Observable<any> {
        return this.http.post("/api/deleteInventoryItemById/",{_id}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),map((response) => response));
    }

    //miggeration
    addFileDatatoAssets(_id): Observable<any> {
        return this.http.post("/api/addInventoryItemAsAssetById/",{_id}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),map((response) => response));
    }
    getAssetsBymUnit(munit): Observable<any> {
        return this.http.post("/api/getAssetsBymUnit/",{munit}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),map((response) => response));
    }
    getAssetsBymUnitArray(munit): Observable<any> {
        return this.http.post("/api/getAssetsBymUnitArray/",{munit}, this.jwt()).pipe(catchError(
            error => of(this.errorService.errorHttp(error))
          ),map((response) => response));
    }

    private jwt() {
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            let headers = new HttpHeaders({
                'Authorization': 'Bearer ' + currentUser.token,
                'Content-Type': 'application/json',
            });
            return { headers: headers };
        }
    }

}